import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import Figure from "../components/Figure"
import { H1, H2, H3, P, Ul } from "../components/VerticalRhythm"
import { Flex, Box } from "rebass"

import PictureSet from "../components/PictureSet"
import imgObj01 from "../imgObjs/imgObj01"
import imgObj02 from "../imgObjs/imgObj02"

import breakpoints from "../theme/breakpoints"

const IndexPage = () => (
  <Layout>
    <SEO title="Karl Anders" keywords={[`Karl Anders`, `Doris Gunselmann`]} />
    <Figure caption="">
      <PictureSet imgObj={imgObj01} />
    </Figure>
    <Figure caption="">
      <PictureSet imgObj={imgObj02} />{" "}
    </Figure>
  </Layout>
)

export default IndexPage
